const dicData = [
  {
    coordinates:[],
    criticalLine:[],
    moderateLine:[],
    cleanLine:[],
    data: [
      {
        id: 1,
        coordinates: [103.83937339379503, 1.310909593157504],
        latitude: 1.310909593157504,
        longitude: 103.83937339379503,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '50%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 2,
        coordinates: [103.83929975398473, 1.3112884769239486],
        latitude: 1.3112884769239486,
        longitude: 103.83929975398473,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '50%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 3,
          coordinates: [103.83920198656165, 1.3116593201999507],
          latitude: 1.3116593201999507,
          longitude: 103.83920198656165,
          type: 'DIC',
          width: 15,
          severity: 'critical',
          fillLevel: '90%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
          id: 4,
          coordinates:  [103.83911762336857, 1.3120971670024772],
          latitude: 1.3120971670024772,
          longitude: 103.83911762336857,
          type: 'DIC',
          width: 15,
          severity: 'critical',
          fillLevel: '85%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 5,
        coordinates: [103.839017175094, 1.312529653444514],
        latitude: 1.312529653444514,
        longitude: 103.839017175094,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '70%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 6,
          coordinates: [103.83888455667318, 1.3126030007282594],
          latitude: 1.3126030007282594,
          longitude: 103.83888455667318,
          type: 'DIC',
          width: 15,
          severity: 'moderate',
          fillLevel: '70%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 7,
        coordinates: [103.83874657655764, 1.3126361458709397],
        latitude: 1.3126361458709397,
        longitude: 103.83874657655764,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 8,
        coordinates: [103.83854961782879, 1.3125406441696867],
        latitude: 1.3125406441696867,
        longitude: 103.83854961782879,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '30%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 9,
          coordinates: [103.83820253172223, 1.3123191757521937],
          latitude: 1.3123191757521937,
          longitude: 103.83820253172223,
          type: 'DIC',
          width: 15,
          severity: 'critical',
          fillLevel: '90%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      /* {
        id: 10,
        coordinates: [103.83785544561329, 1.3120735860261732],
        latitude: 1.3120735860261732,
        longitude: 103.83785544561329,
        type: 'DIC',
        width: 15,
        severity: 'critical',
        fillLevel: '70%',
        averageRainFall: '0.5mm',
        drainIntakeIndex: '15%'
      }, */
      /* {
        id: 11,
        coordinates: [103.8373796788971, 1.311766352977486],
        latitude: 1.311766352977486,
        longitude: 103.8373796788971,
        color: '#00FF00',
        width: 15,
        severity: 'critical',
        fillLevel: '70%',
        averageRainFall: '0.5mm',
        drainIntakeIndex: '15%'
      },
      {
          id: 12,
          coordinates: [103.83688246541192, 1.311443039026443],
          latitude: 1.311443039026443,
          longitude: 103.83688246541192,
         type: 'DIC',
          width: 15,
          severity: 'critical',
          fillLevel: '70%',
          averageRainFall: '0.5mm',
          drainIntakeIndex: '15%'
      }, */
      {
          id: 13,
          coordinates: [103.83644959222954, 1.3111518867656713],
          latitude: 1.3111518867656713,
          longitude: 103.83644959222954,
          type: 'DIC',
          width: 15,
          severity: 'critical',
          fillLevel: '90%',
          averageRainFall: '0.5mm',
          drainIntakeIndex: '15%'
      },
      {
        id: 14,
        coordinates: [103.83590412351327, 1.3107910507152667],
        latitude:  1.3107910507152667,
        longitude: 103.83590412351327,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '70%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 15,
        coordinates: [103.83561333517156, 1.3105937034445674],
        latitude: 1.3105937034445674,
        longitude: 103.83561333517156,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '75%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 16,
          coordinates: [103.8357488013562, 1.3104151171733065],
          latitude: 1.3104151171733065,
          longitude: 103.8357488013562,
          type: 'DIC',
          width: 15,
          severity: 'critical',
          fillLevel: '90%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
          id: 17,
          coordinates: [103.83593788446024, 1.3101614868252511],
          latitude: 1.3101614868252511,
          longitude: 103.83593788446024,
          type: 'DIC',
          width: 15,
          severity: 'critical',
          fillLevel: '95%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 18,
        coordinates: [103.83621811633094, 1.3097416874256567],
        latitude:  1.3097416874256567,
        longitude: 103.83621811633094,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '60%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 19,
          coordinates:  [103.8363428591299, 1.3095952628417535],
          latitude: 1.3095952628417535,
          longitude: 103.8363428591299,
          type: 'DIC',
          width: 15,
          severity: 'moderate',
        fillLevel: '60%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 20,
        coordinates: [103.8363644728135, 1.3096052701436633],
        latitude: 1.3096052701436633,
        longitude: 103.8363644728135,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '60%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 21,
        coordinates: [103.8365964494522, 1.309767062318926],
        latitude:  1.309767062318926,
        longitude: 103.8365964494522,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '60%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 22,
          coordinates: [103.8369785534735, 1.3100441007341885],
          latitude: 1.3100441007341885,
          longitude: 103.8369785534735,
          type: 'DIC',
          width: 15,
          severity: 'clean',
          fillLevel: '40%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 23,
        coordinates: [103.83727487041756, 1.3102514553509081],
        latitude: 1.3102514553509081,
        longitude: 103.83727487041756,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '40%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 24,
        coordinates: [103.83734205848475, 1.3102963045382268],
        latitude: 1.3102963045382268,
        longitude: 103.83734205848475,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '40%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 25,
        coordinates: [103.83757427436916, 1.3104434077857974],
        latitude:  1.3104434077857974,
        longitude: 103.83757427436916,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '70%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 26,
          coordinates: [103.83800195277183, 1.3105757183158318],
          latitude:  1.3105757183158318,
          longitude: 103.83800195277183,
          type: 'DIC',
          width: 15,
          severity: 'moderate',
          fillLevel: '60%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 27,
        coordinates: [103.83876205608328, 1.3107669920196514],
        latitude: 1.3107669920196514,
        longitude: 103.83876205608328,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 28,
        coordinates: [103.83913705123393, 1.3108469568484082],
        latitude: 1.3108469568484082,
        longitude: 103.83913705123393,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '15%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
    ]
  },
  {
    type: 'FLOODING',
    coordinates:[],
    criticalLine:[],
    moderateLine:[],
    cleanLine:[],
    data: [
      {
        id: 1,
        coordinates: [103.80952185215926, 1.3179623906210907],
        latitude: 1.3179623906210907,
        longitude: 103.80952185215926,
        type: 'FLOODING',
        width: 15,
        severity: 'clean',
        fillLevel: '40%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%',
        sensorId: 'AY1234',
        floodingSeverity : 'Normal'
        
      },
      {
        id: 2,
        coordinates: [103.80978867980076, 1.3176578384333055],
        latitude: 1.3176578384333055,
        longitude: 103.80978867980076,
        type: 'FLOODING',
        width: 15,
        severity: 'clean',
        fillLevel: '50%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%',
        sensorId: 'AY1234',
        floodingSeverity : 'Normal'
      },
      {
          id: 3,
          coordinates: [103.80986784821556, 1.3175864581598802],
          latitude: 1.3175864581598802,
          longitude: 103.80986784821556,
          type: 'FLOODING',
          width: 15,
          severity: 'critical',
          fillLevel: '85%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%',
          sensorId: 'AY1234',
          floodingSeverity : 'Critical'
      },
      {
          id: 4,
          coordinates: [103.80998722932338, 1.3174453943123297],
          latitude: 1.3174453943123297,
          longitude: 103.80998722932338,
          type: 'FLOODING',
          width: 15,
          severity: 'critical',
          fillLevel: '90%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%',
          sensorId: 'AY1234',
          floodingSeverity : 'Critical'
      },
      {
        id: 5,
        coordinates:  [103.80992714342318, 1.3173451283772124],
        latitude: 1.3173451283772124,
        longitude: 103.80992714342318,
        type: 'FLOODING',
        width: 15,
        severity: 'moderate',
        fillLevel: '70%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%',
        sensorId: 'AY1234',
        floodingSeverity : 'Normal'
      },
      {
          id: 6,
          coordinates: [103.80978380161514, 1.3170780980427992],
          latitude: 1.3170780980427992,
          longitude: 103.80978380161514,
          type: 'FLOODING',
          width: 15,
          severity: 'moderate',
        fillLevel: '70%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%',
        sensorId: 'AY1234',
        floodingSeverity : 'Normal'
      },
      {
        id: 7,
        coordinates: [103.80954663019799, 1.3166341785618556],
        latitude: 1.3166341785618556,
        longitude: 103.80954663019799,
        type: 'FLOODING',
        width: 15,
        severity: 'clean',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%',
        sensorId: 'AY1234',
        floodingSeverity : 'Normal'
      },
      {
        id: 8,
        coordinates: [103.80932827552687, 1.3160619654495633],
        latitude: 1.3160619654495633,
        longitude: 103.80932827552687,
        type: 'FLOODING',
        width: 15,
        severity: 'clean',
        fillLevel: '20%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%',
        sensorId: 'AY1234',
        floodingSeverity : 'Normal'
      },
      {
          id: 9,
          coordinates: [103.80927876333249, 1.315950383046669],
          latitude: 1.315950383046669,
          longitude: 103.80927876333249,
          type: 'FLOODING',
          width: 15,
          severity: 'clean',
        fillLevel: '30%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%',
        sensorId: 'AY1234',
        floodingSeverity : 'Normal'
      },
      /* {
        id: 10,
        coordinates: [103.80934452751808, 1.3159513664770799],
        latitude:  1.3159513664770799,
        longitude: 103.80934452751808,
        type: 'FLOODING',
        width: 15,
        severity: 'clean',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      }, */
    ]
  },
  {
    type: 'DIC',
    coordinates:[],
    criticalLine:[],
    moderateLine:[],
    cleanLine:[],
    data: [
      {
        id: 1,
        coordinates: [103.92151291202453, 1.3787908814140053],
        latitude: 1.3787908814140053,
        longitude: 103.92151291202453,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '30%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 2,
        coordinates: [103.92145131773776, 1.3785991734211365],
        latitude: 1.3785991734211365,
        longitude: 103.92145131773776,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '20%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 3,
          coordinates: [103.9213962141531, 1.3784658647954728],
          latitude: 1.3784658647954728,
          longitude: 103.9213962141531,
          type: 'DIC',
          width: 15,
          severity: 'critical',
          fillLevel: '90%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
          id: 4,
          coordinates: [103.92093624164971, 1.3786241442500256],
          latitude: 1.3786241442500256,
          longitude: 103.92093624164971,
          type: 'DIC',
          width: 15,
          severity: 'critical',
          fillLevel: '85%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 5,
        coordinates: [103.92078405962656, 1.3786556278840862],
        latitude: 1.3786556278840862,
        longitude: 103.92078405962656,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '70%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 6,
          coordinates: [103.92065972192535, 1.3786347921405167],
          latitude: 1.3786347921405167,
          longitude: 103.92065972192535,
          type: 'DIC',
          width: 15,
          severity: 'moderate',
          fillLevel: '60%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 7,
        coordinates: [103.92057000128278, 1.3785966528726163],
        latitude: 1.3785966528726163,
        longitude: 103.92057000128278,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 8,
        coordinates: [103.92042402791412, 1.3785974465278201],
        latitude: 1.3785974465278201,
        longitude: 103.92042402791412,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '30%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 9,
          coordinates: [103.92037757859492, 1.3786328472196487],
          latitude: 1.3786328472196487,
          longitude: 103.92037757859492,
          type: 'DIC',
          width: 15,
          severity: 'moderate',
        fillLevel: '70%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 10,
        coordinates:  [103.92037440059966, 1.3787547655030181],
        latitude: 1.3787547655030181,
        longitude: 103.92037440059966,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '65%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 11,
        coordinates: [103.92047074664896, 1.3787815144349906],
        latitude: 1.3787815144349906,
        longitude: 103.92047074664896,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '75%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 12,
          coordinates: [103.92073585086217, 1.3787087681356667],
          latitude: 1.3787087681356667,
          longitude: 103.92073585086217,
          type: 'DIC',
          width: 15,
          severity: 'critical',
          fillLevel: '90%',
          averageRainFall: '0.5mm',
          drainIntakeIndex: '15%'
      },
      /* {
          id: 13,
          coordinates: [103.92084517831034, 1.378659814172991],
          latitude: 1.378659814172991,
          longitude: 103.92084517831034,
          color: '#00FF00',
          width: 15,
          severity: 'critical',
          fillLevel: '70%',
          averageRainFall: '0.5mm',
          drainIntakeIndex: '15%'
      },
      {
        id: 14,
        coordinates: [103.92128769494872, 1.3784983873302252],
        latitude:  1.3784983873302252,
        longitude: 103.92128769494872,
        color: '#FF0000',
        width: 15,
        severity: 'clean',
        fillLevel: '60%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      }, */
      {
        id: 15,
        coordinates:  [103.92140567665729, 1.3784580851214798],
        latitude: 1.3784580851214798,
        longitude: 103.92140567665729,
        type: 'DIC',
        width: 15,
        severity: 'critical',
        fillLevel: '90%',
        averageRainFall: '0.5mm',
        drainIntakeIndex: '15%'
      },
      {
          id: 16,
          coordinates: [103.92140163177771, 1.3784490414840747],
          latitude: 1.3784490414840747,
          longitude: 103.92140163177771,
          type: 'DIC',
          width: 15,
          severity: 'critical',
          fillLevel: '85%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
          id: 17,
          coordinates: [103.92129161130373, 1.3780997561908777],
          latitude: 1.3780997561908777,
          longitude: 103.92129161130373,
          type: 'DIC',
          width: 15,
          severity: 'clean',
          fillLevel: '50%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 18,
        coordinates: [103.92122785345396, 1.3779123740229693],
        latitude:  1.3779123740229693,
        longitude: 103.92122785345396,
        type: 'DIC',
        width: 15,
        severity: 'clean',
          fillLevel: '10%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
          id: 19,
          coordinates:  [103.92108603085347, 1.377480666536087],
          latitude: 1.377480666536087,
          longitude:103.92108603085347,
          type: 'DIC',
          width: 15,
          severity: 'critical',
          fillLevel: '90%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 20,
        coordinates: [103.92087961304793, 1.376853632424755],
        latitude: 1.376853632424755,
        longitude: 103.92087961304793,
        type: 'DIC',
        width: 15,
        severity: 'critical',
          fillLevel: '85%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 21,
        coordinates:  [103.92087643505266, 1.3766662501578821],
        latitude: 1.3766662501578821,
        longitude: 103.92087643505266,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '70%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 22,
          coordinates: [103.92091436481655, 1.3765870149677824],
          latitude: 1.3765870149677824,
          longitude: 103.92091436481655,
          type: 'DIC',
          width: 15,
          severity: 'moderate',
          fillLevel: '60%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 23,
        coordinates: [103.92093931318016, 1.3765250833084508],
        latitude: 1.3765250833084508,
        longitude: 103.92093931318016,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 24,
        coordinates: [103.92094911657966, 1.3764674775333958],
        latitude: 1.3764674775333958,
        longitude: 103.92094911657966,
        type: 'DIC',
        width: 15,
        severity: 'clean',
        fillLevel: '30%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 25,
        coordinates: [103.92091997578711, 1.3764012199890914],
        latitude:   1.3764012199890914,
        longitude: 103.92091997578711,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '70%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 26,
          coordinates: [103.92085621793967, 1.376347940093936],
          latitude: 1.376347940093936,
          longitude: 103.92085621793967,
          type: 'DIC',
          width: 15,
          severity: 'moderate',
          fillLevel: '60%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 27,
        coordinates: [103.92074053449818, 1.3764028072997405],
        latitude: 1.3764028072997405,
        longitude: 103.92074053449818,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '70%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 28,
        coordinates: [103.92075682859945, 1.3765225627554116],
        latitude: 1.3765225627554116,
        longitude: 103.92075682859945,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '70%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 28,
        coordinates: [103.92082937541943, 1.376787235298778],
        latitude: 1.376787235298778,
        longitude: 103.92082937541943,
        type: 'DIC',
        width: 15,
        severity: 'moderate',
        fillLevel: '75%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
    ]
  }

  
];


export default dicData

/*
const dicData = [
    {
      id: 1,
      coordinates: [103.775341, 1.410695],
      latitude: 1.410695,
      longitude: 103.775341,
      color: '#FF0000',
      width: 15,
      severity: 'clean',
      fillLevel: '60%',
      averageRainFall: '0.2mm',
      drainIntakeIndex: '5%'
    },
    {
      id: 2,
      coordinates: [103.776457, 1.410891],
      latitude: 1.410891,
      longitude: 103.776457,
      color: '#00FF00',
      width: 15,
      severity: 'moderate',
      fillLevel: '10%',
      averageRainFall: '0.2mm',
      drainIntakeIndex: '5%'
    },
    {
        id: 3,
        coordinates: [103.782961, 1.410957],
        latitude: 1.410957,
        longitude: 103.782961,
        color: '#00FF00',
        width: 15,
        severity: 'critical',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
    },
    {
        id: 4,
        coordinates: [103.785020, 1.411729],
        latitude: 1.411729,
        longitude: 103.785020,
        color: '#FF0000',
        width: 15,
        severity: 'clean',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 5,
        coordinates: [103.788469, 1.412593],
        latitude: 1.412593,
        longitude: 103.788469,
        color: '#00FF00',
        width: 15,
        severity: 'moderate',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 6,
          coordinates: [103.788469, 1.412593],
          latitude: 1.412593,
          longitude: 103.788469,
          color: '#00FF00',
          width: 15,
          severity: 'critical',
          fillLevel: '10%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 7,
        coordinates: [103.793811, 1.414065],
        latitude: 1.414065,
        longitude: 103.793811,
        color: '#FF0000',
        width: 15,
        severity: 'clean',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 8,
        coordinates: [103.797649, 1.415277],
        latitude: 1.415277,
        longitude: 103.797649,
        color: '#00FF00',
        width: 15,
        severity: 'moderate',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 9,
          coordinates:  [103.802836, 1.416185],
          latitude: 1.416185,
          longitude: 103.802836,
          color: '#00FF00',
          width: 15,
          severity: 'critical',
          fillLevel: '10%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 10,
        coordinates: [103.804342, 1.415319],
        latitude: 1.415319,
        longitude: 103.804342,
        color: '#FF0000',
        width: 15,
        severity: 'clean',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 11,
        coordinates: [103.805093, 1.414740],
        latitude: 1.414740,
        longitude: 103.805093,
        color: '#00FF00',
        width: 15,
        severity: 'moderate',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 12,
          coordinates: [103.805812, 1.414386],
          latitude: 1.414386,
          longitude: 103.805812,
          color: '#00FF00',
          width: 15,
          severity: 'critical',
          fillLevel: '70%',
          averageRainFall: '0.5mm',
          drainIntakeIndex: '15%'
      },

    
  ];


*/