const MenuList = [    
    {
        id:'ODCinfo', 
        name: 'MS-7505'
    },
    {
        id:'mcODCinfo', 
        name: 'MC-9713L'
    }
]


//COMBI ARM/DIC SENSOR




export default MenuList

