export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    //console.log('User Access Token: '+user.signInUserSession.idToken.jwtToken);
    if (user && user.signInUserSession) {
      return { Authorization: 'Bearer ' + user.signInUserSession.idToken.jwtToken };
    } else {
      return {};
    }
    // const token = 'eyJraWQiOiI1Zk5ONXZncWFneXpZWXBHUHZjY2ZBMEw5all3U0FjU1BaM2JxVFUxQWVFPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI4Y2U3YzJmMi03OTg2LTQ4NTAtYTQ3Yi01YjJiMDc2YTM2YWIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoZWFzdC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoZWFzdC0xX0NvVGZ1VXFTUiIsImNvZ25pdG86dXNlcm5hbWUiOiI4Y2U3YzJmMi03OTg2LTQ4NTAtYTQ3Yi01YjJiMDc2YTM2YWIiLCJhdWQiOiI0MnVjM2ZnNnVtdGZqanJvN3BvdGkxazEzcSIsImV2ZW50X2lkIjoiNGI3MWUzM2MtZGZiNC00ZjI5LWE1MDctZGYwY2E5OGEyMjg4IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2ODAwNjU5MjcsIm5hbWUiOiJBdXRvbWF0ZW5vdyIsImV4cCI6MTY4MDE1MjMyNywiaWF0IjoxNjgwMDY1OTI3LCJlbWFpbCI6ImF1dG9tYXRlbm93LmNvQGdtYWlsLmNvbSJ9.JBK0LdBpKIKKqUwAsgzCp6EVp9fMN9QDAxupKqgFRdN0vcKSCN6o6GODqkbldiHhHgiVF3tbNLCPt1GVAutDui4GmKHmjQgmA1IpqeftAGF1KbpHzfy3wiKzc4qIMRpbB0cGvkrdDirLsSY7wp55QEKA2TVz4guPaeTC9mHTv50Z6U1APYEahj6By6hSbFThn8eAI_d2m6zvUo2PVSF7Hzk9s5FGi_Impg-KtROYQSYIAblxCSZQgyCah1i1wXzcPn3gucKeeLO-zFeI9kyLDW8iOtLd2E5UKghIrSUBjCr1UM9vpxXdSQhwJbOwGKFyEu35iIUmBeUAHjw7ph-jKg'
    // return { Authorization: 'Bearer ' + token };
}

// import axios from  'axios';
// //mcODCinfo ODCinfo
// let baseUrl = `https://api.pixvisonz.com/v1/geo/map/plots/view?type=ODCinfo`
// i

// const token = 'Bearer eyJraWQiOiI1Zk5ONXZncWFneXpZWXBHUHZjY2ZBMEw5all3U0FjU1BaM2JxVFUxQWVFPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI4Y2U3YzJmMi03OTg2LTQ4NTAtYTQ3Yi01YjJiMDc2YTM2YWIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoZWFzdC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoZWFzdC0xX0NvVGZ1VXFTUiIsImNvZ25pdG86dXNlcm5hbWUiOiI4Y2U3YzJmMi03OTg2LTQ4NTAtYTQ3Yi01YjJiMDc2YTM2YWIiLCJhdWQiOiI0MnVjM2ZnNnVtdGZqanJvN3BvdGkxazEzcSIsImV2ZW50X2lkIjoiOWNjNzE5M2UtN2Y2MC00YjM0LWE5ZjUtZDk0NGNiNDBiZTRiIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2Nzg5NjkyNzcsIm5hbWUiOiJBdXRvbWF0ZW5vdyIsImV4cCI6MTY3OTA1NTY3NywiaWF0IjoxNjc4OTY5Mjc3LCJlbWFpbCI6ImF1dG9tYXRlbm93LmNvQGdtYWlsLmNvbSJ9.lL4z3v47WL1YJ4eSkm1HBWDMznO9YFdZbNWf48gajEoMBhqO72P-I0GWz18bVkt4qtfyyRIziKgGxbqgfcUAnc7aVMOqe-UgwjTe6sodN053OTR6_c4BcLjuCnb8XobHyZ94FN0AmNXJFV3ygL8pnwjf2ZcX-jj3THiqMpZH7wTSsQEttgP53uzRtdSSRwxlp2gat-pxc-YPYLBHEtMvfc9PvIU0ylbEfWAB5IXr03Tkd9xqzOQ_IRgbDXilGdcHwH55EkdU1bBGPcxGRWkvXLVi6PwwetL6xqNXUP9JJEOdegpIxwBfrsgMkZtBA8GwEkbky_0-Ng8MI28TylD-rg'
