import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import colorConfigs from '../configs/colorConfigs';
import { Card, CardContent } from '@mui/material';
import Logo from "../Assets/ctm-01.png"
import Copyright from './Copyright';
import { useNavigate } from "react-router-dom";
import AuthService from "../Services/auth"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ToastController from '../Controller/ToastController/ToastController';

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required'),
    email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required'),
    passwordrep: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

export default function SignUp() {
    const { register, handleSubmit, formState: { errors } } = useForm({resolver: yupResolver(validationSchema)});
    const navigate = useNavigate();
    const [successBox, setSuccessBox]  = React.useState(false);
    const [toastMsg, setToastMsg]= React.useState({
        msg: ``,
        status: ``
    });

    const handleRegistration = (data) => {
        console.log('handleRegistration: '+JSON.stringify(data));
        AuthService.register(data).then(resp => {
            console.log(JSON.stringify(resp));
            setSuccessBox(true)
            setToastMsg((prevState) => ({
                ...prevState,
                msg:  "Verification link is sent to " + data.email,
                status: 'success'
            }));
            setTimeout(() => navigate("/signin"), 3000)
        }).catch(err => {
          console.log(JSON.stringify(err));
          setSuccessBox(true)
            setToastMsg((prevState) => ({
                ...prevState,
                msg:  "Sorry! We are not able to register "+data.email,
                status: 'error'
            }));
        })
      }

      const handleError = (errors) => {
        console.log(errors)
        let e = errors && errors.email && errors.email.message;
        let p =  errors && errors.password && errors.password.message;
        let x = e ? e : p ? p : "Sorry! We are not able to register "

        setSuccessBox(true)
        setToastMsg((prevState) => ({
            ...prevState,
            msg:  x,
            status: 'error'
        }));
    };

    const onClose =(e)=>{
      setSuccessBox(false)
    }


  return (
    //<div >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{backgroundColor: colorConfigs.mainBg, height: `100vh`}}
        >
            <Card sx={{ maxWidth: 500 }}>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={Logo} width={60}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <Typography component="h1" variant="body2" style={{textAlign: `center`}}>
                        Register now!
                    </Typography>
                </Grid>
                <form onSubmit={handleSubmit(handleRegistration, handleError)}>
                  <Grid container spacing={2}  style={{padding: `10px`}}> 
                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="email"
                              label="Email"
                              {...register('email')}
                              fullWidth
                          />
                      </Grid>  

                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="name"
                              label="Name"
                              {...register('name')}
                              fullWidth
                          />
                      </Grid>  

                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="password"
                              label="Password"
                              {...register('password')}
                              fullWidth
                          />
                      </Grid>   

                      <Grid item xs={12} md={12} sm={12}>
                          <TextField
                              required
                              id="oupasswordrep"
                              label="Confirm Password"
                              {...register('passwordrep')}
                              fullWidth
                          />
                      </Grid>                       
                  </Grid>                    
                </form>
                <Grid item xs={12} md={12} sm={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained">
                      Sign Up
                    </Button>
                </Grid>
                <Grid container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center" style={{padding: `10px`}}
                  spacing={1}>
                  <Grid item xs={4} sm={6} md={6}>
                    <Link onClick={()=>  navigate("/recoverpassword")}  variant="body2">
                      Recover Password
                    </Link>
                  </Grid>
                  <Grid item xs={8} sm={6} md={6} >
                    <Link onClick={()=>  navigate("/signin")}  variant="body2" style={{float: 'right'}}>
                      {"Already have an account? Sign In"}
                    </Link>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Copyright />
                </Grid>
              </CardContent>
            </Card>
            {successBox &&(
            <ToastController 
            isOpen={successBox}
            onClose={onClose}
            message={toastMsg.msg}
            status={toastMsg.status}/>
          )}
        </Grid>
    //</div>
  );
}