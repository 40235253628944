import React from 'react';
import CustomIconSvg from '../../Assets/WaterDropIcon.ico'
import CleanIcon from '../../Assets/Clean.svg';
import ModerateIcon from '../../Assets/Moderate.svg';
import CriticalIcon from '../../Assets/Critical.svg';
import CleanFlood from '../../Assets/Flooding.svg'
import FloodGiff from '../../Assets/output-onlinegiftools.gif'
import ModerateFlood from '../../Assets/FloodingModerate.svg'

const CustomIcon = (props) => {
  let sData = props && props.data && props.data.severity;
  let type =  props && props.data && props.data.type;

  if(type == 'FLOODING'){
    return (
      <div>
         {sData  == "critical" &&(
           <img src={FloodGiff} width={50} height={50}/>
        )}
        {sData  == "moderate" &&(
            <img src={ModerateFlood} width={50} height={50}/>
        )}
        {sData  == "clean" &&(
          <img src={CleanFlood} width={50} height={50}/>
        )}
      </div>
    );
  }
  else{
    return (
      <div>
        {sData  == "critical" &&(
          <img src={CriticalIcon} width={40} height={40}/> 
        )}
        {sData  == "moderate" &&(
          <img src={ModerateIcon} width={40} height={40}/> 
        )}
        {sData  == "clean" &&(
          <img src={CleanIcon} width={40} height={40}/> 
        )}
      </div>
    );
  }

  
};

export default CustomIcon;
