const DropDownList = [    
    // {
    //     id:'ODCinfo', 
    //     name: 'MS-7505'
    // },
    // {
    //     id:'mcODCinfo', 
    //     name: 'MC-9713L'
    // },
    {
        id:'dicSensor', 
        name: 'DIC SENSOR'
    },
    /* {
        id:'smartCombi', 
        name: 'SMART COMBI ARM'
    } */
]

export default DropDownList;