import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: `#3e4a57`,
    color: "#fff",
    hoverBg: colors.pink["400"],
    activeBg: colors.pink["600"],
  },
  topbar: {
    bg: "#fff",
    color: "#000"
  },
  mainBg: '#2e3742'
};

export default colorConfigs;