import * as React from 'react'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HomeIcon from '@mui/icons-material/Home';
import ComponentOne from '../Components/Maps/ComponentOne';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import StreetviewIcon from '@mui/icons-material/Streetview';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BarChartIcon from '@mui/icons-material/BarChart';
import StreetsLayout from './StreetsLayout';
import TrendLayout from './TrendLayout';

const HomeComponent = React.lazy(() => import('../Components/Maps/index'))
const AddUser = React.lazy(() => import('../Components/User/AddUser'))
const ModifyStreets = React.lazy(() => import('../Components/Streets/ModifyStreets'))
const AddStreets = React.lazy(() => import('../Components/Streets/AddStreets'))
const LiveReport = React.lazy(()=>import('../Components/Reports/LiveReports'))
const TrendAnalysisOverview = React.lazy(()=>import('../Components/Trend/TrendAnalysisOverview/index'))
const TrendAnalysis = React.lazy(()=>import('../Components/Trend/TrendAnalysis/index'))
const Areas = React.lazy(()=>import('../Components/PedestrainAreas/Areas'))

// import HomeComponent from '../Components/Maps/index';
// import AddUser from '../Components/User/AddUser'
// import ModifyStreets from '../Components/Streets/ModifyStreets';
// import AddStreets from '../Components/Streets/AddStreets';
// import LiveReport from '../Components/Reports/LiveReports';


const appRoutes = [
    {
        index: true,
        element: <HomeComponent />,
        state: "Home"
    },
    {
        path: "/dashboard",
        element: <HomeComponent />,
        state: "Home",
        sidebarProps: {
          displayText: "Live Overview",
          icon: <HomeIcon />
        }
    }, 

   /*  {
      path: "/livereports",
      element: <LiveReport />,
      state: "LiveReport",
      sidebarProps: {
        displayText: "Live Reports",
        icon: <SummarizeIcon />
      }
    }, 
  
    {
      path: "/trend",
      element: <TrendLayout />,
      state: "trend",
      sidebarProps: {
        displayText: "Trend",
        icon: <AssessmentIcon />
      },
      child: [
        {
          path: "/trend/analysis",
          element: <TrendAnalysis />,
          state: "TrendAnalysis",
          sidebarProps: {
            displayText: "Trend Analysis",
            icon: <TrendingUpIcon />
          }
        }, 
        
        {
          path: "/trend/overview",
          element: <TrendAnalysisOverview />,
          state: "TrendAnalysisOverview",
          sidebarProps: {
            displayText: "Trend Analysis Overview",
            icon: <BarChartIcon />
          }
        }, 
      ]
    },

    {
      path: "/adduser",
      element: <AddUser />,
      state: "AddUser",
      sidebarProps: {
        displayText: "Add User",
        icon: <PersonAddIcon />
      }
  }, 

  {
    path: "/streets",
    element: <StreetsLayout />,
    state: "street",
    sidebarProps: {
      displayText: "Streets",
      icon: <StreetviewIcon />
    },
    child: [
      {
        path: "/streets/addstreets",
        element: <AddStreets />,
        state: "street.addstreets",
        sidebarProps: {
          displayText: "Add Streets",
          icon: <AddRoadIcon />
        }
      },
      {
        path: "/streets/modifystreets",
        element: <ModifyStreets />,
        state: "street.modifystreets",
        sidebarProps: {
          displayText: "Streets",
          icon: <EditRoadIcon />
        },
      }
    ]
  },
 
  {
    path: "/areas",
    element: <Areas />,
    state: "Area",
    sidebarProps: {
      displayText: "Area",
      icon: <SummarizeIcon />
    }
  }, 
 */


 /*  {
     path: "/streets",
    element: <ModifyStreets />,
    //state: "Streets",
    sidebarProps: {
      displayText: "Streets",
      icon: <EditRoadIcon />
    },
    child: [
      {
        path: "/modifystreets",
        element: <ModifyStreets />,
        state: "ModifyStreets",
        sidebarProps: {
          displayText: "Modify Streets",
          icon: <EditRoadIcon />
        }
      }, 
      {
        path: "/addstreets",
        element: <AddStreets />,
        state: "AddStreets",
        sidebarProps: {
          displayText: "Add Streets",
          icon: <AddRoadIcon />
        }
      }, 
    ]
},*/
];

export default appRoutes;